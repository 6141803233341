<template>
  <div class="theme-switch">
    <input
      type="radio"
      id="theme-light"
      value="light"
      v-model="activeTheme"
      @change="setTheme('light')"
    />
    <label for="theme-light" aria-label="Helles Theme">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M3.55 19.09L4.96 20.5L6.76 18.71L5.34 17.29M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12C18 8.68 15.31 6 12 6M20 13H23V11H20M17.24 18.71L19.04 20.5L20.45 19.09L18.66 17.29M20.45 5L19.04 3.6L17.24 5.39L18.66 6.81M13 1H11V4H13M6.76 5.39L4.96 3.6L3.55 5L5.34 6.81L6.76 5.39M1 13H4V11H1M13 20H11V23H13"
        />
      </svg>
    </label>

    <input
      type="radio"
      id="theme-dark"
      value="dark"
      v-model="activeTheme"
      @change="setTheme('dark')"
    />
    <label for="theme-dark" aria-label="Dunkles Theme">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z"
        />
      </svg>
    </label>

    <input
      type="radio"
      id="theme-system"
      value="system"
      v-model="activeTheme"
      @change="setTheme('system')"
    />
    <label for="theme-system" aria-label="PC Theme">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M4,6H20V16H4M20,18A2,2 0 0,0 22,16V6C22,4.89 21.1,4 20,4H4C2.89,4 2,4.89 2,6V16A2,2 0 0,0 4,18H0V20H24V18H20Z"
        />
      </svg>
    </label>
  </div>
</template>
<script lang="ts" setup>
import { useThemeSwitchStore } from "@/stores/themeSwitch";
import { storeToRefs } from "pinia";
import Theme from "@/types/Theme";
import { onMounted } from "vue";

const store = useThemeSwitchStore();
const { activeTheme } = storeToRefs(store);

function getSystemPreference(): Theme {
  if (
    typeof window.matchMedia === "function" &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return "dark";
  }
  return "light";
}

function setCssClassToBody(theme: Theme) {
  if (theme === "system") {
    theme = getSystemPreference();
  }

  if (theme === "dark") {
    document.body.classList.add("theme-dark");
  } else {
    document.body.classList.remove("theme-dark");
  }
}

function setTheme(newTheme: Theme) {
  store.setTheme(newTheme);
  setCssClassToBody(newTheme);
}

onMounted(() => {
  setCssClassToBody(activeTheme.value);
});
</script>

<style lang="scss">
.theme-switch {
  --active: #{$primary500};
  --inactive: #{$primary200};

  @include dark {
    --active: #{$secondary500};
    --inactive: #{$primary200};
  }

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  input {
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked + label path {
      fill: var(--active);
    }
  }

  label {
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    path {
      fill: var(--inactive);
    }

    &:hover path {
      fill: var(--active);
    }
  }
}
</style>
